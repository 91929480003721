<template>
  <div class="grow center wrap">
    <form @submit.prevent="post" class="container center d-col">
      <s-logo />
      <h1 class="d-flex green--text font-48 text-center">
        <div class="bold">{{ page }} Password Reset</div>
      </h1>
      <div class="missingText font-14" :class="{ 'red--text': error }">
        {{ msg }}
      </div>
      <s-text-field
        block
        bar
        password
        type="password"
        v-model="pass1"
        :label="$t('resetPassword.password')"
      ></s-text-field>
      <s-text-field
        block
        details
        password
        type="password"
        v-model="pass2"
        :label="$t('resetPassword.repeat')"
      ></s-text-field>
      <s-btn type="submit" block class="green mt-15" height="40">
        {{ $t("resetPassword.change") }}
      </s-btn>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pass1: "",
      pass2: "",
      msg: this.$t("resetPassword.please_set"),
      error: false,
      passLength: 8,
    };
  },
  methods: {
    async post() {
      if (!this.validatePassword()) return;

      let ob = { password: this.pass1, hash: this.$route.params.hash };
      if (this.isPartners) {
        let res = await this.$store.dispatch("user/resetResellerPassword", ob);
      } else {
        let res = await this.$store.dispatch("user/resetPassword", ob);
      }
      let page = "";
      if (this.isPartners) page = "/partners";
      if (res.status == "ok") return this.$router.push(`${page}/login`);
      if (res.status == "error") {
        this.msg = res.error;
        this.error = true;
        return false;
      }
    },
    async validateLink() {
      return;
      let hash = this.$router.currentRoute.params.hash;
      let res = await this.$store.dispatch("user/validateLink", hash);
      if (res.status == "ok") this.passLength = res.passwordLength;
      if (res.status == "error") {
        this.msg = res.error;
        this.error = true;
      }
    },
    validatePassword() {
      this.error = false;
      this.msg = "";

      if (this.pass1 == "") {
        this.msg = this.$t("resetPassword.password_empty");
        this.error = true;
        return false;
      }
      if (this.pass1 != this.pass2) {
        this.msg = this.$t("resetPassword.password_no_not_match");
        this.error = true;
        return false;
      }
      if (this.pass1.length < this.passLength) {
        this.msg =
          this.$t("resetPassword.password_must_be") +
          this.passLength +
          this.$t("resetPassword.or_more_char");
        this.error = true;
        return false;
      }

      return true;
    },
  },
  computed: {
    isPartners() {
      return this.$route.path.includes("partners");
    },
    page() {
      return this.isPartners ? "Partner" : "Customer";
    },
  },
  mounted() {
    this.validateLink();
  },
};
</script>


<style scoped lang="less">
.container {
  width: 100%;
  max-width: 400px;
  margin: 10px;
}

.textRed {
  color: #df5151;
}
</style>
