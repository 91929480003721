<template>
<div class="h100 vh100 d-flex d-col">
  <TopToolbar />
  <ResetPassword />
</div>
</template>

<script>
import TopToolbar from "@/components/Toolbars/TopToolbar.vue";
import ResetPassword from "@/components/Auth/ResetPassword/ResetPassword.vue";

export default {
  name: "ResetPasswordComp",
  components: {
    ResetPassword,
    TopToolbar
  },
};
</script>

